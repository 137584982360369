'use client';

import { Client } from '@/app/lib/client/client';
import { redirect } from 'next/navigation';
import { useEffect } from 'react';

export const dynamic = 'force-dynamic';

export default function Page() {
	useEffect(() => {
		const userId = Client.GetUserId();
		if (userId) {
			redirect(`/${userId}`);
		}
	}, []);
}
